/***
 * Helper script for jpicker.js (/controls/jpicker)
 */
'use strict';

import ESPopUp from "../popup/ESPopUp";
import '../colorpicker/jpicker-1.1.6';
import {kt} from "../../common/ESGlobalsUtil";

import './css/jPicker-1.1.6.css';

export default class Colorpicker {

    constructor(){
        console.debug('colorpicker constructor');
        this.test = '';
    }

    getColorPopupDiv(){

        let div = document.getElementById("colorPickerDiv");
        if (!div){
            div = document.createElement("DIV");
            div.id = "colorPickerDiv";
        }
        return div;
    }

    initColorPicker(colorPopup, boundControlId, boundStyle, callback){
        // init the picker
        // for help on these settings see:  http://www.digitalmagicpro.com/jPicker/

        let useLive = (boundStyle?true:false);
        let hexColor = $('#'+boundControlId).css(boundStyle);

        if (hexColor != 'transparent' && $.trim(hexColor.replace(/^rgba.*,(.+)\)/,'$1')) != '0') { // IE/FF return 'transparent' while Chrome return rgba.
            hexColor = this.colorToHex(hexColor); // just in case this is an RGB color, we need to convert it to Hex.
        } else {
            hexColor = '#ffffff'; // defaulted to #ffffff if nothing exist yet.
        }

        $('#colorPickerDiv').jPicker(
            {
                window:
                    {
                        title: "&nbsp;",
                        liveUpdate: useLive
                    },
                color:
                    {
                        active: new $.jPicker.Color({ hex: hexColor  })
                    },
                images:
                    {
                        clientPath: 'controls/colorpicker/images/' /* Path to image files */
                    },
                localization: {
                    text: {
                        title: "Drag Markers To Pick A Color",
                        newColor: kt("colorPickerPopup.newColor"),
                        currentColor: kt("colorPickerPopup.currentColor"),
                        ok: kt('button.ok.text'),
                        cancel: kt('button.cancel.text')
                    },
                    tooltips: {
                        colors: {
                            newColor: "New Color - Press &ldquo;OK&rdquo; To Commit",
                            currentColor: "Click To Revert To Original Color"
                        },
                        buttons: {ok: "Commit To This Color Selection", cancel: "Cancel And Revert To Original Color"},
                        hue: {
                            radio: "Set To &ldquo;Hue&rdquo; Color Mode",
                            textbox: "Enter A &ldquo;Hue&rdquo; Value (0-360&deg;)"
                        },
                        saturation: {
                            radio: "Set To &ldquo;Saturation&rdquo; Color Mode",
                            textbox: "Enter A &ldquo;Saturation&rdquo; Value (0-100%)"
                        },
                        brightness: {
                            radio: "Set To &ldquo;Brightness&rdquo; Color Mode",
                            textbox: "Enter A &ldquo;Brightness&rdquo; Value (0-100%)"
                        },
                        red: {radio: "Set To &ldquo;Red&rdquo; Color Mode", textbox: "Enter A &ldquo;Red&rdquo; Value (0-255)"},
                        green: {
                            radio: "Set To &ldquo;Green&rdquo; Color Mode",
                            textbox: "Enter A &ldquo;Green&rdquo; Value (0-255)"
                        },
                        blue: {
                            radio: "Set To &ldquo;Blue&rdquo; Color Mode",
                            textbox: "Enter A &ldquo;Blue&rdquo; Value (0-255)"
                        },
                        alpha: {
                            radio: "Set To &ldquo;Alpha&rdquo; Color Mode",
                            textbox: "Enter A &ldquo;Alpha&rdquo; Value (0-100)"
                        },
                        hex: {textbox: "Enter A &ldquo;Hex&rdquo; Color Value (#000000-#ffffff)"}
                    }
                }

            },
            // callback
            (color) =>
            {
                if (callback){
                    let hex = color.val('hex');
                    if(hex && hex.length == 6)
                        hex = '#' + hex;
                    else
                        hex =  '#ffffff'; // 'transparent' (maybe transparent later)
                    let alphaVal = color.val('a');
                    callback(hex, alphaVal);
                }
                colorPopup.hide();
            },
            // livecallback
            (color) => {
                this.cp_updateBoundControl(color, boundControlId, boundStyle);
            },
            // cancel callback
            (color) => {
                this.cp_updateBoundControl(color, boundControlId, boundStyle);
                colorPopup.hide();
            }
        );

    }

    cp_updateBoundControl(color, boundControlId, boundStyle){
        let hex = color.val('hex');
        $('#'+boundControlId).css(
            boundStyle, hex && hex.length == 6 ? '#' + hex : '#ffffff' // 'transparent' (maybe transparent later)
        );// prevent IE from throwing exception if hex is empty
        let alphaVal = color.val('a');
        $('#'+boundControlId).css('filter','alpha(opacity='+alphaVal+')');
    }

    invertColor(hexTripletColor) {
        let color = hexTripletColor;
        color = color.substring(1);           // remove #
        color = parseInt(color, 16);          // convert to integer
        color = 0xFFFFFF ^ color;             // invert three bytes
        color = color.toString(16);           // convert to hex
        color = ("000000" + color).slice(-6); // pad with leading zeros
        color = "#" + color;                  // prepend #
        return color;
    }


    colorToHex(rgb) {
        if (rgb.substr(0, 1) === '#') {
            return rgb;
        }
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';

    }

}

/**
 * Launchs the Color Selector Popup
 * @param boundControlId (optional) - string id for a control you want to bind this to.
 * @param boundStyle (optional) -  the style on boundControlId you would like to update the color for.
 * @param callback (required) - callback w the hex color val as the param.
 * @return
 */
export function launchColorSelectorPopup (boundControlId, boundStyle, callback){

    let cp = new Colorpicker();

	let colorPopup = new ESPopUp('colorPopupSelector', 'GENERIC_EMPTY');
	colorPopup.labelKey = 'colorPickerPopup.title';
	colorPopup.allowResize = false;
	colorPopup.hideScrolls = true;
	colorPopup.bodyHeight = "300";
	colorPopup.bodyWidth = "555";
	colorPopup.parentElement = this;
	//colorPopup.positionOverControl(document.getElementById(boundControlId));
	colorPopup.destroyOnClose = true;
	colorPopup.genericBodyDiv = cp.getColorPopupDiv();
	colorPopup.init();

	if (typeof(callback) == 'string')
		callback = eval(callback);

	cp.initColorPicker(colorPopup, boundControlId, boundStyle, callback);
	colorPopup.show();

}



