'use strict';

import ESList from "../list/ESList";
import {getGlobalByName, kt} from "../../common/ESGlobalsUtil";
import {
    getMultiFieldDisplayNames,
    getUCScreenProperty,
    setUCRecentItem,
    setUCScreenProperty
} from "../../common/ESGlobals";

let MCS_popup = null;
let MCS_MultiList = null;
let MCS_dhxLayout = null;
let MCS_selectList = null;
let MCS_esLookup = null;
let MCS_delim = '';

export function MultiListColumnSelector_init(thePopup){

	MCS_popup = thePopup;
	MCS_delim = getGlobalByName('globalMultiValueSelectionDelimiter');

	MultiListColumnSelector_initSelectList();

}

function MultiListColumnSelector_doSelectAdd(){


	// get the selected node from the tree
	//let data = MCS_MultiList.getSelectedItemData();
	let dataArr = MCS_MultiList.getMultiSelectedItemData();
	let itemHTML = '';
	let tree = MCS_MultiList.estree;

	for (let i in dataArr){

		let data = dataArr[i];
		let thisId = data[MCS_MultiList.idKey];
		if (!thisId)
			thisId = data['iid']; // will be the case for runtime params.

		// Code to not allow selection, with exception case for when in leaf mode.
		// 'SEL_ALL' is the default.
		let isRootNodeNoSelect = tree.getIsRootNodeNoSelect(thisId);
		if (isRootNodeNoSelect ||
			(data && data['selectable']=="N" && (tree.childSelMode && tree.childSelMode != 'SEL_ALL'))){
			continue; // NICE TO HAVE: add all of the children, if any. Problem, though is that we'd have to force expand the parent to make server call to get kids (if they aren't loaded yet).
		}

		// if this is a multiValueSelection item, then we need to split it up into individuals
		if(thisId.indexOf(MCS_delim)>-1){

			let multiIds = thisId.split(MCS_delim);
			for(let j in multiIds){
				// check if this item already exists
				if (MCS_selectList.getItemIndexById(multiIds[j]) >= 0 )
					continue;

				// added to selected list
				let displayKey = 'display_'+multiIds[j];
				let iconKey = 'icon_'+multiIds[j];
				itemHTML = MCS_getItemHTML(data[displayKey], data[iconKey]);
				MCS_selectList.addItem(itemHTML, multiIds[j]);
			}

		}else{

			// check if this item already exists
			if (MCS_selectList.getItemIndexById(thisId) >= 0 )
				continue;

			// added to selected list
			itemHTML = MCS_getItemHTML(data['display'], data['icon']);
			MCS_selectList.addItem(itemHTML, thisId);
		}
	}



	// ?? Stash more info in UserData? --> MCS_selectList.setUserData(id, 'listdata', listItem);

}

function MCS_getItemHTML(display, icon){
	// runtime param difficult to be passed through, so hard code it here (probably fix later if possible).
	if (display.indexOf('@=')!=-1)
		icon = 'fa epc-sticky-note-o';
	if (!icon)
		return display;
	let img = '<div class="fontIcoDiv" >'+
		'<i class="'+icon+'" ></i>'+
		'</div>';
	let cap = img+'<span>'+display+'</span>';
	return cap;
}


function MultiListColumnSelector_doSelectRemove(){

	if (MCS_selectList.length == 0){
		return;
	}

	MCS_selectList.removeSelectedItem();
}

function MultiListColumnSelector_doSelectRemoveAll(){
	MCS_selectList.clearAll();
}

function MultiListColumnSelector_getSelectedItems(){
	let items = [];

	let length = MCS_selectList.getLength();
	for (let i=0; i<length; i++){
		let id = MCS_selectList.getItemId(i);
		let obj = {};
		obj.id = id;
		obj.caption =  MCS_selectList.getItemCaption(id);
		obj.icon =  MCS_selectList.getIcon(id);
		items.push(obj);
	}

	return items;
}

export function MultiListColumnSelector_assignItems(){

	if (MCS_selectList){

		let items = MultiListColumnSelector_getSelectedItems();

		// if no items for get it
		if (!items || items.length == 0){
			MCS_esLookup.selectedId = '';
			MCS_esLookup.selectedDisplayValue = '';
			MCS_esLookup.itemSelectHandler( '', '', null, MCS_esLookup.isUnderCheckboxIsChecked );
			return true;
		}

		let ids = [];
		let caps = [];
		let icons = [];

		for (let i=0; i<items.length; i++){
			//console.debug( items[i].id );
			//console.debug( items[i].caption );
			ids.push(items[i].id);
			caps.push(items[i].caption);
			icons.push(items[i].icon);
		}

		// check for runtime param + values. we can't support this now.
		let checkIds = ids.join(MCS_delim);
		if (checkIds.indexOf('@=')!=-1 && checkIds.indexOf(MCS_delim)!=-1){
			alert(kt('popup.multilist.alert.runtime.text'));
			return false;
		}

		MCS_esLookup.selectedId = (ids.length==1) ? ids[0] : ids.join(MCS_delim); // if just 1 item, don't return an array.
		MCS_esLookup.selectedDisplayValue = (caps.length==1) ? caps[0] : caps; // if just 1 item, don't return an array.
		MCS_esLookup.selectedDisplayIcon = icons;
		MCS_esLookup.itemSelectHandler( MCS_esLookup.selectedId, MCS_esLookup.selectedDisplayValue, null, MCS_esLookup.isUnderCheckboxIsChecked );

		if (MCS_esLookup.mode != 'popupOnly'){
			MCS_esLookup.repaintLookup(true);
		}

		if (MCS_MultiList.idKey){
			// assume these are all of the same type, so f item we can use it's attribs for all?
			let attribs = MCS_MultiList.getItemAttribs(MCS_MultiList.idKey, items[0]);
			if (MCS_MultiList.idKey == 'CATEGORY_VALUE_ID'){
				// once again, under assumption that all cat vals are of the same type.
				attribs['categoryTypeId'] = MCS_MultiList.catTypeId;
				attribs['usageType'] = MCS_MultiList.usageType;
			} else if (MCS_MultiList.idKey === 'TASK_ID') {
				// once again, under assumption that all tasks are of the same type.
				attribs['taskTypeId'] = MCS_MultiList.taskTypeId;
			}

			// add to UC
			if (MCS_esLookup.selectedId &&
				MCS_esLookup.selectedId != 'TREE_ALL' &&
				MCS_esLookup.selectedId != 'TREE_NONE' &&
				MCS_esLookup.selectedId.indexOf('@=')==-1){
				setUCRecentItem(attribs.key, MCS_esLookup.selectedId, Date.parse(new Date()), attribs );
			}
		}
	}

	return true; // will close the popup.
}

export function MultiListColumnSelector_setESLookup(look){
	MCS_esLookup = look;
}


export function MultiListColumnSelector_setMultiList(multiList){
	MCS_MultiList = multiList;
}

export function MultiListColumnSelector_setEventHandlers(){
	// config double click handlers
	MCS_MultiList.estree.dblclickHandler = function(){
		MultiListColumnSelector_doSelectAdd();
		return true;
	};
	MCS_MultiList.estree.onKeyPressHandler = function(key, ctrl, shift, eventObj){
		if (key == 39 && ctrl)
			MultiListColumnSelector_doSelectAdd();
		return true;
	};
	MCS_MultiList.recentList.dblclickHandler = function(){
		MultiListColumnSelector_doSelectAdd();
		return true;
	};
	MCS_selectList.onDblClicked = function(){
		MultiListColumnSelector_doSelectRemove();
		return true;
	};
}

function MultiListColumnSelector_initSelectList(){

	if (!MCS_selectList){
		MCS_selectList = new ESList('MCS_selectList');
		MCS_selectList.controlDiv = document.getElementById("mc_selectItemsDiv");
		MCS_selectList.enableMultiSelect = true;
		MCS_selectList.enableDeleteKey = true;
		MCS_selectList.init();
	}else{
		MCS_selectList.clearAll();
	}

}

export function MultiListColumnSelector_loadSelectList(){
	let ids = MCS_esLookup.selectedId.split(MCS_delim);
	let caps = MCS_esLookup.selectedDisplayValue;
	if (!caps || !caps.length || (caps.length != ids.length)){
		let coContextId;
		let isRuntime;
		let skipOverrideLogic;
		if (MCS_esLookup.urlParams){
			coContextId = MCS_esLookup.urlParams['coContextId'];
			isRuntime = MCS_esLookup.urlParams['isRuntime'];
			skipOverrideLogic = MCS_esLookup.urlParams['skipOverrideLogic'];
		}
		getMultiFieldDisplayNames(MCS_esLookup.selectedIdKey, MCS_esLookup.selectedId, function(textVals, icons){
			MCS_esLookup.selectedDisplayValue = textVals;
			MCS_esLookup.selectedDisplayIcon = icons;
			MultiListColumnSelector_reallyLoadSelectList();
		}, coContextId, isRuntime, skipOverrideLogic);
	}else{
		MultiListColumnSelector_reallyLoadSelectList();
	}
}

function MultiListColumnSelector_reallyLoadSelectList(){
	// add previously selected items (if any)
	if (MCS_esLookup.selectedId){

		let cap = '';

		// if just 1 item, go directly otherwise syncing of captions gets ugly.
		if (MCS_esLookup.selectedId.indexOf(MCS_delim)<0){
			let dispVal = MCS_esLookup.selectedDisplayValue;
			if ($.isArray(MCS_esLookup.selectedDisplayValue)) {
				if (MCS_esLookup.selectedDisplayValue.length > 0)
					dispVal = MCS_esLookup.selectedDisplayValue[0];
				else
					dispVal = '';
			}
			cap =  MCS_getItemHTML(dispVal, MCS_esLookup.selectedDisplayIcon);
			MCS_selectList.addItem(cap, MCS_esLookup.selectedId);
			return;
		}

		let ids = MCS_esLookup.selectedId.split(MCS_delim);

		let caps = MCS_esLookup.selectedDisplayValue;
		if (!caps || !caps.length || (caps.length != ids.length))
			return;

		let icons = MCS_esLookup.selectedDisplayIcon;
		if (!icons || !icons.length || (icons.length != ids.length))
			icons = new Array(ids.length);

		for (let i=0; i<ids.length; i++){
			cap = MCS_getItemHTML(caps[i], icons[i]);
			MCS_selectList.addItem(cap, ids[i]);
		}
	}
}

export function MultiListColumnSelector_setSizes(){

	if(dhx4.isChrome || dhx4.isKHTML){ // unfortunate "jarring" for only chrome. Otherwise the left multilist ends up w zero height and you can't see it.
		let dims = MCS_popup.getDimensions();
		MCS_popup.setDimensions( dims[0], dims[1]+1 );
		setTimeout(function(){
			MCS_popup.setDimensions( dims[0], dims[1]-1 );
		},100);
	}

	let totW =  $(MCS_dhxLayout.base).width();
	MCS_dhxLayout.cells("c").setWidth( totW - (MCS_dhxLayout.cells("a").getWidth() + 38)  );
	MCS_dhxLayout.cells("a").setWidth( totW - (MCS_dhxLayout.cells("c").getWidth() + 38)  );
	MCS_dhxLayout.cells("b").setWidth(38);

	MCS_dhxLayout.setSizes();
	MCS_MultiList.setSizes();

	MCS_selectList.grid.setSizes();
}

export function MultiListColumnSelector_attachDOMComponents(popup, layCell){

	// 3 col dhtml layout
	MCS_dhxLayout = layCell.attachLayout("3W");
	//MCS_MultiList.dhxLayout = MCS_dhxLayout; // not sure this is necessary.
	MCS_dhxLayout.attachEvent("onPanelResizeFinish", function(cells){

		let cs = cells;
		// cs will be ["a", "b"] or ["b", "c"]
		let totW =  $(MCS_dhxLayout.base).width();
		if (cs[0]=='a') // resized 'a', so fix 'c'
			MCS_dhxLayout.cells("c").setWidth( totW - (MCS_dhxLayout.cells("a").getWidth() + 38)  );
		else if (cs[1]=='c') // resized 'c' so fix 'a'
			MCS_dhxLayout.cells("a").setWidth( totW - (MCS_dhxLayout.cells("c").getWidth() + 38)  );

		MCS_dhxLayout.cells("b").setWidth(38);
		MultiListColumnSelector_setSizes();

		let width = MCS_dhxLayout.cells("a").getWidth();
		setUCScreenProperty('','MCS_dhxLayout_a', width);
		width = MCS_dhxLayout.cells("c").getWidth();
		setUCScreenProperty('','MCS_dhxLayout_c', width);

	});

	let w = getUCScreenProperty('','MCS_dhxLayout_a', '');
	if(w)
		MCS_dhxLayout.cells("a").setWidth(w);
	MCS_dhxLayout.cells("a").setText(kt('popup.multilist.available.text'));
	MCS_dhxLayout.cells("a").hideArrow();
	// FYI: left cell gets attached using MultiListColumnSelector_setLeftPanelComponent()


	// The Center Buttons
	let imgTbl = document.createElement('TABLE');
	imgTbl.className = 'thinElement';
	imgTbl.style.marginTop = '50px';
	imgTbl.style.width = '30px';
	let iRow = imgTbl.insertRow(-1);
	iRow.align = 'center';
	let c = iRow.insertCell(-1);
	c.style.padding = '0';
	c.style.width = '28px';
	let button = document.createElement('BUTTON');
	button.setAttribute('id', 'move_right_button');
	button.className = 'multiListButton';
	button.onclick = MultiListColumnSelector_doSelectAdd;
	let img = document.createElement('IMG');
	img.id = 'btn_col_sel_add';
	img.src = getGlobalByName("globalImagePath") + 'icons/angle-right.png';
	img.title = kt('popup.multilist.additem.text');// 'Add item \n[Ctrl + right arrow keys]';
	img.className = 'thinElement';
	button.appendChild(img);
	c.appendChild(button);

	iRow = imgTbl.insertRow(-1);
	iRow.align = 'center';
	c = iRow.insertCell(-1);
	c.style.padding = '0';
	button = document.createElement('BUTTON');
	button.setAttribute('id', 'move_left_button');
	button.className = 'multiListButton';
	button.onclick = MultiListColumnSelector_doSelectRemove;
	img = document.createElement('IMG');
	img.id = 'btn_col_sel_remove';
	img.src = getGlobalByName("globalImagePath") + 'icons/angle-left.png';
	img.title = kt('popup.multilist.removeitem.text');// 'Remove item \n[Delete key]';
	img.className = 'thinElement';
	button.appendChild(img);
	c.appendChild(button);

	iRow = imgTbl.insertRow(-1);
	iRow.align = 'center';
	c = iRow.insertCell(-1);
	c.style.padding = "10px 5px 10px 5px";
	button = document.createElement('BUTTON');
	button.setAttribute('id', 'move_left_all_button');
	button.className = 'multiListButton';
	button.onclick = MultiListColumnSelector_doSelectRemoveAll;
	img = document.createElement('IMG');
	img.id = 'btn_col_sel_remove_all';
	img.src = getGlobalByName("globalImagePath") + 'icons/double-angle-left.png';
	img.title = kt('popup.multilist.removeall.text');// 'Remove all items';
	img.className = 'thinElement';
	button.appendChild(img);
	c.appendChild(button);

	MCS_dhxLayout.cells("b").hideHeader();
	MCS_dhxLayout.cells("b").setWidth(38);
	MCS_dhxLayout.cells("b").attachObject(imgTbl);

	// The right 'Selected Columns'
	let selectItemsDiv = document.createElement('DIV');
	selectItemsDiv.setAttribute('id', 'mc_selectItemsDiv');
	selectItemsDiv.style.height = '100%';
	selectItemsDiv.style.width = '100%';
	document.body.appendChild(selectItemsDiv);

	MCS_dhxLayout.cells("c").setText(kt('popup.multilist.selected.text'));
	MCS_dhxLayout.cells("c").hideArrow();
	MCS_dhxLayout.cells("c").attachObject(selectItemsDiv);

	w = getUCScreenProperty('','MCS_dhxLayout_c', 210);
	MCS_dhxLayout.cells("c").setWidth(w);

	setTimeout(function(){
		MultiListColumnSelector_setSizes();
	},500);

}

export function MultiListColumnSelector_setLeftPanelComponent( comp ){
	MCS_dhxLayout.cells("a").attachObject( comp );
}

export function MultiListColumSelector_destroy(){
	MCS_selectList = null;
}


