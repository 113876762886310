/* ? Copyright 2010 EcoSys Management LLC -- All Rights Reserved -- CONFIDENTIAL */
'use strict';

/******
 *  ESCombo.js -  a wrapper class for dhtmlxcombo
 */

import {getXMLText} from "../../scripts/xmlhelper";
import {getXMLFromServerAsync} from "../../scripts/clientserver";

import './combo.scss';

window.__ESComboList = [];
window.ESComboFind = function( name ){
    return __ESComboList[name];
};
// adds a menu to the list of menus. using an associative array
window.ESComboPush = function( spread ) {
    __ESComboList[spread.name] = spread;
};
window.ESComboRemove = function( name ){
    __ESComboList[name] = null;
};

// This is required for double byte type ahead to be able to provide UTF-8 charset
// Alternative solution: apply URIEncoding=UTF-8 in Application Server configuration
if (window.dhx4 && window.dhx4.ajax)
    window.dhx4.ajax.method="post";
if (window.dhtmlx)
    window.dhtmlx.skin = 'dhx_skyblue'; // Will cause global default for ALL dhtmlx controls. Best if this was in ESGlobals, but it needs to happen after dhtmlx-epc.js is loaded.


//-----------------------------

export default class ESCombo{

    constructor (thisName) {
        this.name = thisName.replace(/_/, ''); //identifier for namespacing this spreadsheet object (NO UNDERSCORES ALLOWED IN NAME)
        ESComboPush(this);

        this.combo = null; // the dhtmlxMenu objects
        this.width = 0;
        this.parentControl = null;

        this.selectChangeHandler = null; // call back for when an item is selected, but the dropdown is still up.
        this.itemSelectedHandler = null; // call back for when the dropdown is closed and a diff item has been selected.
        this.openListHandler = null; // call back for when the list is opened.
        this.lostFocusHandler = null; // call back for when lost focus (onBlur)
        this.keyPressedHandler = null; // call back for when key pressed (onKeyPressed)

        this.enableTypeAheadUrl = null;

        this.manualLoadWithFiltering = false; // option to load items manual, but still get filtering (type-ahead)s

        this.showImages = false;
    }

    init(containerId, cboName, width, optionType, tabIndex, controlStyle){

        this.width = width;

        if (!optionType && this.showImages)
            optionType = 'image';

        this.combo = new dhtmlXCombo(containerId, cboName, width, optionType, tabIndex);

        this.combo.setOptionWidth(175);  //added to keep a resonable min combo option width (would be best if it was "auto" but dhtmlx removed that feature in v4).

        if (this.enableTypeAheadUrl != null){
            this.combo.enableFilteringMode(true,this.enableTypeAheadUrl,false,false);
            // no need to show the down arrow image when in type-ahead mode.
            this.combo.getButton().style.display = 'none';

        }else if (this.manualLoadWithFiltering){
            this.combo.enableFilteringMode('between');
            // no need to show the down arrow image when in type-ahead mode.
            this.combo.getButton().style.display = 'none';
        }

        /**
         * Fires a new combo val is selected and but the dropdown is still up.
         */
        this.combo.attachEvent("onSelectionChange", () => {

            //if (typeof text == 'string')
            //    text = text.replace(/\&/g,'&amp;');
            if (this.selectChangeHandler)
                if (this.selectChangeHandler() == false)
                    return false;
            return true;
        });
        /**
         * Fires after the combo val is selected and the dropdown closes.
         */
        this.combo.attachEvent("onChange", () => {
            if (!controlStyle || controlStyle != 'PROCESSFORMRUN') {
                let value = this.getSelectedValue();
                let text =  this.getSelectedText();
                if (typeof text == 'string')
                    text = text.replace(/\&/g,'&amp;');
                if (this.itemSelectedHandler)
                    this.itemSelectedHandler(value, text);
                return true;
            }
        });
        this.combo.attachEvent("onOpen", () => {
            if (this.openListHandler)
                this.openListHandler();
            return true;
        });
        this.combo.attachEvent("onBlur", () => {
            if (this.lostFocusHandler)
                this.lostFocusHandler();
            return true;
        });

        this.combo.attachEvent("onXLE",() => {
            // nothing to do yet.
        });

        this.combo.attachEvent("onKeyPressed", (code, event) => {
            this.keyWasPressed = true;
            if (this.keyPressedHandler)
                this.keyPressedHandler(code, event);
        });

    }

    setFocus(){
        if (this.combo && this.combo.getInput())
            this.combo.getInput().focus();
    }

    refreshTypeAheadUrl(url){
        this.enableTypeAheadUrl = url;
        if (this.combo && this.combo.conf)
            this.combo.conf.f_url  = url;
    }

    /**
     * Sets the width of the combo
     * @param width -- int (no 'px')
     */
    setWidth(width){
        this.combo.setSize(width);
    }

    addOptions(objArray){
        this.combo.addOption(objArray);
    }

    addOption(label, value, selected, silent){
        this.combo.addOption(value, label+"");
        if (selected){
            this.selectItemByValue(value, silent);
        }
    }

    removeOption(value){
        this.combo.deleteOption(value);
    }

    removeAll(){
        this.combo.clearAll();
    }

    async loadOptionsFromURL(url) {

        const [xml] = await getXMLFromServerAsync(url, 'xml');
        this.combo.parse(getXMLText(xml), () => {
            // TODO
            //alert('loaded');
            //if(onLoadComplete)
            //	onLoadComplete();
        });
    }

    sort(mode){
        this.combo.sort(mode); // 'asc' or 'desc'
    }


    enableTypeAhead(url){
        this.enableTypeAheadUrl = url;
    }

    disable(disable){
        this.combo.disable(disable);
    }

    /**
     *
     * @param mode - true or false
     * @param autosearch - true by default.
     * @return
     */
    setReadOnly(mode, autosearch){
        this.combo.readonly(mode, autosearch);
    }

    getSelectedValue(){
        return this.combo.getSelectedValue();
    }

    getSelectedText(){
        return this.combo.getSelectedText();
    }

    getIndexByValue(val){
        return this.combo.getIndexByValue(val);
    }
    getOptionByLabel(val){
        return this.combo.getOptionByLabel(val);
    }
    selectItemByValue(val, silent){

        // don't fire event handlers if silent
        let tmpChange, tmpSel;
        if (silent) {
            tmpChange = this.selectChangeHandler;
            this.selectChangeHandler = null;
            tmpSel = this.itemSelectedHandler;
            this.itemSelectedHandler = null;
        }

        let ind = this.getIndexByValue(val);
        this.combo.selectOption(ind);

        if (silent) {
            this.selectChangeHandler = tmpChange;
            this.itemSelectedHandler = tmpSel;
        }


    }
    selectOption(ind){
        this.combo.selectOption(ind);
    }
    clearSelection(silent){
        let tmp;
        if (silent) {
            tmp = this.itemSelectedHandler;
            this.itemSelectedHandler = null;
        }
        this.combo.unSelectOption();
        this.combo.setComboText('');
        if (silent){
            this.itemSelectedHandler = tmp;
        }
    }
    setComboText(val){
        this.combo.setComboText(val);
    }
    getComboText(){
        return this.combo.getComboText();
    }
    selectComboText(){
        this.combo.getInput().select();
    }


    destructor(){
        if (this.combo){
            this.combo.unload();
            this.combo = null;
        }
    }

} // End class



