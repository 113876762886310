/* ? Copyright 2007 EcoSys Management LLC -- All Rights Reserved -- CONFIDENTIAL */
'use strict';

/**
* An EcoSys EPC-specific list (aka html select) that uses dhtmlXGrid as it's main component.
* Author:  Scott Chinn
*/

import '../../common/ESGlobals';
import {getGlobalByName} from "../../common/ESGlobalsUtil";

import './list.scss';


window.__ESListList = [];

window.ESListFind = function( name ){
	return window.__ESListList[name];
};
// adds a spread to the list of spreads. using an associative array
window.ESListPush = function( list ) {
	window.__ESListList[list.name] = list;
};
window.ESListRemove = function( name ){
	window.__ESListList[name] = null;
};

export default class ESList {

	constructor(thisName) {

		window.dhx_globalImgPath=getGlobalByName("globalImagePath") + "icons/";

		this.name =  thisName.replace(/_/, ''); //identifier for namespacing this spreadsheet object (NO UNDERSCORES ALLOWED IN NAME)
		this.controlDiv = null; // where to render this control
		this.containerElement = null;
		this.bottomMargin = null;
		this.listType = null; // the listType of this spreadsheet.
		this.onSelectionChange = null; // handler for selection change.
		this.afterRowDrag = null; // handler for when rows are dragged.
		this.enableDragAndDrop = true;
		this.enableMultiSelect = false;
		this.enableDeleteKey = false;
		this.enableFilter = false;
		this.headerCaption = 'Caption'; // Will show if enableFilter is turned on.
		this.disabledRegionEndIndex = 0; // lock all rows up to this guy.
		this.showContextMenu = false;
		this.onBeforeContextMenu = null;
		this.onKeyPress = null;
		this.onDblClicked = null;
		this.contextMenu = null;
		this.deletedValues = [];
		window.ESListPush(this);

		this.grid; // the dhtmlXGridObject

	}

	init() {

		this.controlDiv.className = "listControlDiv";

		this.grid = new dhtmlXGridObject(this.controlDiv);

		this.grid.setSkin('eco');
		this.grid.setImagePath("controls/dhtmlxSuite/imgs/");
		this.grid.setIconPath(getGlobalByName("globalImagePath") + "icons/");
		this.grid.setHeader(this.headerCaption);
		if (this.enableFilter)
			this.grid.attachHeader('#text_filter');
		else
			this.grid.setNoHeader(true);
		this.grid.setDelimiter('|');
		this.grid.setColAlign("left");
		this.grid.setColTypes("ro");
		this.grid.enableTooltips("false"); // seems tool tips are causing problems when we save ... xml parsing erros. Not sure why.
		this.grid.enableExcelKeyMap();
		if (this.showContextMenu)
			this.grid.enableContextMenu(this.contextMenu.menu);

		this.grid.init();

		if (this.enableDragAndDrop)
			this.grid.enableDragAndDrop(true);

		if (this.enableMultiSelect)
			this.grid.enableMultiselect(true);

		//custom attribs to include in serialization
		this.grid.xml.row_attrs = [];
		this.grid.xml.row_attrs.push('_locked');

        this.grid.unescapeHTML = (str) => {
            return $("<div/>").html(str).text();
        }
		this.grid.isXMLDoc = (text) => {
            return $.isXMLDoc(text);
        }

		this.lastSelectedRowId;
		let rowsSelectEventId=this.grid.attachEvent("onRowSelect", (rid, cid) => {
			if (this.onSelectionChange && this.lastSelectedRowId != rid)
				this.onSelectionChange(rid);
			this.lastSelectedRowId = rid;
			return true;
		});

		/*
		  sId - id of the source item;
		  tId - id of the target item;
		  dId - id of the dropped item (has sense for mercy drag-n-drop);
		  sObj - source grid object;
		  tObj - target grid object;
		  sCol - index of the column from which drag started;
		  tCol - index of the column in which drop occurs.
		 ***/
		let onDropEventId = this.grid.attachEvent("onDrop", (sId,tId,dId,sObj,tObj,sCol,tCol) => {
			if (this.afterRowDrag)
				this.afterRowDrag(sId,tId,dId,sObj,tObj,sCol,tCol);
		});

		let onDragEventId = this.grid.attachEvent("onDrag", (sourceId,targetId) => {
			// don't allow dropping into locked rows.
			if (this.grid.getRowById(targetId)._locked)
				return false;

			return true;
		});


		let onBeforeDragEventId = this.grid.attachEvent("onBeforeDrag", (id) => {
			if (this.grid.getRowById(id)._locked) return false;        // deny drag if row is locked
			return true;                                              // allow drag for any other case
		});

		let onBeforeContextMenuId = this.grid.attachEvent("onBeforeContextMenu", (rid, cid, grid) => {
			if(this.onBeforeContextMenu)
				return this.onBeforeContextMenu(rid, cid, grid);

		});

		let onDblClickId = this.grid.attachEvent("onRowDblClicked", (rid, cid, grid) => {
			if(this.onDblClicked)
				return this.onDblClicked(rid, cid, grid);

		});

		let onKeyPress = this.grid.attachEvent("onKeyPress", (key, cntrl, shift, event)  => {
			if (this.onKeyPress)
				return this.onKeyPress(this, key,cntrl, shift, event);
			// default for this control.
			switch(key){
				case 46: // DEL
					if(this.enableDeleteKey)
						this.removeSelectedItem();
					break;
			}
			return true;
		});


	}

	loadFromUrl(url) {
		this.grid.load(url, "xml");
	}

	clearAll() {
		this.grid.clearAll();
		this.disabledRegionEndIndex = 0;
		this.deletedValues = [];
	}

	addItem(caption, value, ind) {
		if (ind <= this.disabledRegionEndIndex && this.disabledRegionEndIndex != 0) // don't allow insert before disabled region.
			ind = this.disabledRegionEndIndex + 1;
		let row = this.grid.addRow(value, caption, ind );
		if (row)
			return row.idd;
	}

	getLength() {
		return this.grid.getRowsNum();

	}

	setSelected(index, nofireEvent, allowSameRowSelection) {
		if (allowSameRowSelection) // a little trick to allow same row to fire event again
			this.lastSelectedRowId = -99;
		return this.grid.selectRow(index, nofireEvent?false:true);

	}

	getSelectedValue() {
		return this.grid.getSelectedRowId();

	}

	/**
	 * Prevent editting and flag as disabled. Blocks move and drag and drop.
	 * @return
	 */
	lockItem(id, locked) {
		this.grid.lockRow(id, locked);
		this.grid.setRowAttribute(id, '_locked', locked);
	}

	isItemLocked(id) {
		let row = this.grid.getRowById(id);
		if (!row)
			return false;
		return row._locked?row._locked:false;
	}

	getSelectedIndex() {
		return this.grid.getRowIndex( this.grid.getSelectedRowId() );
	}

	getItemIndexById(id) {
		return this.grid.getRowIndex( id );
	}

	getItemId(index) {
		return this.grid.getRowId( index );
	}

	forEachRow(callback) {
		this.grid.forEachRow(callback); // callback will pass rowId param.
	}

	/**
	 * Attaches a block of user defined data to a list item. When sorting or
	 * moving of rows happens, this value always sticks w the item.
	 * @param id - the id of the item to set userdata to.
	 * @param nameAttrib - the string name of this userdata item (you can have many per item)
	 * @param data - the data
	 */
	setUserData(id, nameAttrib, data) {
		this.grid.setUserData(id, nameAttrib, data);
	}

	getUserData(id, nameAttrib) {
		return this.grid.getUserData(id, nameAttrib);
	}

	setItemCaption(value, caption, imgOverride) {
		let cell = this.grid.cellById(value, "0");

		// case of img is pretty ugly, but this seems to work.
		// this block requires the font image div to be followed by a span.
		// eg: <Div><I></DIV>
		//  		<SPAN>Configure Menus</SPAN>
		if(cell.cell.firstChild.tagName == 'DIV' &&  $('span:first', cell.cell) && imgOverride != true){
			$('span:first', cell.cell).html(caption);
			caption = $(cell.cell).html();
		}

		cell.setValue(caption);
	}

	getItemCaption(value, caption) {
		let cell = this.grid.cellById(value, "0");
		let text = (typeof cell.cell.textContent != 'undefined') ? cell.cell.textContent : cell.cell.innerText;
		return text; // so img tag doesn't get returned.
	}

	setIcon(value, icon) {
		let cell = this.grid.cellById(value, "0");
		let $ico = $(cell.cell).find('.fontIcoDiv I');
		if($ico.length)
			$ico.attr('class', icon);
	}

	getIcon(value) {
		let cell = this.grid.cellById(value, "0");
		let $ico = $(cell.cell).find('.fontIcoDiv I');
		if($ico.length)
			return $ico.attr('class');
	}

	showIcon(value, showIt) {
		let cell = this.grid.cellById(value, "0");
		$(cell.cell).find('.fontIcoDiv').toggleClass('hidden', !showIt);
	}

	removeSelectedItem(noFireEvent) {
		let selIndex = this.getSelectedIndex();
		let selId = this.grid.getSelectedRowId();
		if (!selId || selId == '')
			return;
		this.deletedValues.push( selId );
		this.grid.deleteSelectedRows();
		if (this.getLength()>0){
			let idx = selIndex-1;
			if(idx<0)
				idx = 0;
			this.setSelected(idx, noFireEvent);
		}
	}

	removeItem(value, noFireEvent) {
		let selIndex = this.getSelectedIndex();
		this.deletedValues.push( value );
		this.grid.deleteRow( value );
		if (this.getLength()>0){
			let idx = selIndex-1;
			if(idx<0)
				idx = 0;
			this.setSelected(idx, noFireEvent);
		}
	}

	moveSelectedRowUp() {
		let id = this.grid.getSelectedRowId();
		if (this.grid.getRowById(id)._locked) return false;
		let ind = this.grid.getRowIndex(id) - 1;
		if (ind <= this.disabledRegionEndIndex && this.disabledRegionEndIndex != 0 ) // don't allow move up to before disabled region.
			return;
		this.grid.moveRowUp(id);
	}

	moveSelectedRowDown() {
		let id = this.grid.getSelectedRowId();
		if (this.grid.getRowById(id)._locked) return false;
		this.grid.moveRowDown(this.grid.getSelectedRowId());
	}

	getGrid(){
        return this.grid;
    }

	setSizes() {

		this.grid.setSizes();
        //reset the grid box height
        let extraH = 0;
        let contEle = this.containerElement;
        if (!this.containerElement) {
            contEle = this.controlDiv;
        }else{
            extraH = 0;
        }
        let hdrH = $(this.grid.hdrBox).height();
        let topMargin = 0;
		if (this.enableFilter)
			topMargin = $(this.grid.hdr).offset().top - $(contEle).offset().top;
        let bottomMargin = this.bottomMargin?this.bottomMargin:0; // TODO: not sure how to calc bottom margin
        let newH = $(contEle).height() - (hdrH + topMargin + bottomMargin);
        this.grid.objBox.style.height = newH - extraH + 'px';

		// set the 1st column width to size of content (this way horiz scroll will show properly).
		this.grid.adjustColumnSize(0);

	}
}


