/* ? Copyright 2005 EcoSys Management LLC -- All Rights Reserved -- CONFIDENTIAL */
'use strict';

import {getGlobalByName} from "../common/ESGlobalsUtil";

/**
* Returns an xml element with an attribute set to a specific value.
*/
export function  createElementWithAttribute(root, nodeName, attribute, value){

	let element = root.createElement(nodeName);
	//let attrib = root.createAttribute(attribute);
	element.setAttribute(attribute, value);
	return element;
}

/**
* Returns an xml element with the text set to a specific value.
*/
export function  createTextElement(root, nodeName, value){

	let element = root.createElement(nodeName);
	setNodeValue(element, value, root);
	return element;
}

/**
* updates an xml context LookupField element with the right id.
*/
export function  updateLookupField(xml, field, id){

	if (!id)
		id = '';

	try{
		let nodes = xml.selectNodes('ContextItem/LookupFields/LookupField');
		for (let i = 0; i < nodes.length; i++) {
			let fname = nodes[i].getAttribute('name');
			if (fname == field){
				nodes[i].setAttribute('id', id);
				return;
			}
		}
	}catch(e){
		throw e;
	}
}

/**
* returns a specific attribute value for a specific context LookupField
*/
export function  getLookupFieldInfo(xml, field, attrib){
	try{
		let nodes = xml.selectNodes('ContextItem/LookupFields/LookupField');
		for (let i = 0; i < nodes.length; i++) {
			let fname = nodes[i].getAttribute('name');
			if (fname == field){
				return nodes[i].getAttribute(attrib);
			}
		}
	}catch(e){
		throw e;
	}
}

/**
* Returns the value for the field you are looking for. (REQUIRES jQuery)
* @param item - Any xml block with <userdata> nodes in it (doesn't matter at what level)
* @param name - the user data field name you are looking for. Must be link <userdata name='apple'>Stuff</userdata>
*/
export function  getUserDataValue(item, name){

	if (typeof(jQuery)=='undefined')
		return null;

	let node = $(item).find("userdata[name='" + name + "']"); // Uses jQUERY
	if (node.length != 1)
		return null;
	return Sarissa.getText(node[0]);
}

export function  getItemArray(xml){
	if (!xml)
		return;

	//first try listdata
	let items = xml.selectNodes('//ListData/ListItem');
	if (!items || items.length == 0){
		items = xml.selectNodes('//tree/item');
	}
	return items;
}

export function  getSingleNode(xml, nodePath){
	if (!xml)
		return

	if(typeof xml.selectSingleNode == 'unknown' || xml.selectSingleNode ) // 'unknown' should only be in IE, && means this function really exists.
		return xml.selectSingleNode(nodePath);

	// in many cases now xml will actually be just a hash map, and the value object added
	if ($.isArray(xml)){
		if(xml['ListItem'] && xml['ListItem'].nodeName == 'ListItem'){
			return xml['ListItem'].selectSingleNode(nodePath);
		}

	}

}

/**
* Returns the value for the field you are looking for.
* @param xml Any xml block that has a valueobject embedded in it.
* @param fiedl the value object field you are looking for
*/
export function  getVOFieldValue(xml, field){
	//try{

		if (!xml)
			return;

		// in many cases now xml will actually be just a hash map. Let's try it first.
		if ($.isArray(xml)){
			return xml[field]?xml[field]:'';
		}

	let node = getVOFieldNode(xml, field);
		if (node == null){
			// try other way:
			return getUserDataValue(xml, field);
		}
		if (node.getAttribute('internalValue'))
			return node.getAttribute('internalValue');
		else
			return Sarissa.getText(node);
	//}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
	//	throw e;
	//}
}

/**
* Returns the value for the field you are looking for.
* @param voxml Any Value Object xml block
* @param field the value object field you are looking for
*/
export function getVOFieldValueFromVO(voxml, field){
	try{
		let node = voxml.selectSingleNode('//valueobject/field[@name="' + field + '"]');
		return Sarissa.getText(node);
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
}

/**
* Returns the value for the field you are looking for.
* @param listItem Any list item xml block
* @param fiedl the value object field you are looking for
*/
export function getVOFieldValueFromListItem(listItem, field){
	try{
		let node = listItem.selectSingleNode('valueobject/field[@name="' + field + '"]');
		return Sarissa.getText(node);
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
}

export function setVOFieldValueFromListItem(listItem, field, value, rootXML){
	try{
		let node = listItem.selectSingleNode('valueobject/field[@name="' + field + '"]');
		setNodeValue(node, value, rootXML);
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
}

export function setVOFieldValueFromVOItem(voxml, field, value, rootXML){
	try{
		let nodes = voxml.childNodes;
		for (let i = 0; i < nodes.length; i++) {
			let fname = nodes[i].getAttribute('name');
			if (fname == field){
				setNodeValue(nodes[i], value, rootXML);
				return;
			}
		}
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
}

export function setVOFieldNodeFromVOItem(voxml, field, node){
	try{
		let nodes = voxml.childNodes;
		for (let i = 0; i < nodes.length; i++) {
			let fname = nodes[i].getAttribute('name');
			if (fname == field){
				nodes[i].appendChild(node);
				return;
			}
		}
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
}

/**
* Returns the value for the field you are looking for.
* @param voxml Any Value Object xml block
* @param fiedl the value object field you are looking for
*/
export function getFieldValueFromVOItem(voxml, field){
	let result = null, nodes, node, fname;
	try{
		nodes = voxml.childNodes;
		for (let i = 0, ii = nodes.length; i < ii; i++) {
			node = nodes[i];
			fname = node.getAttribute('name');
			if (fname == field){
				result = Sarissa.getText(node);
				break;
			}
		}
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
	nodes = node = fname = null;
	return result;
}

/**
* Returns the node for the field you are looking for.
* @param voxml Any Value Object xml block
* @param fiedl the value object field you are looking for
*/
export function getFieldNodeFromVOItem(voxml, field){
	let result = null, nodes, node, fname;
	try{
		nodes = voxml.childNodes;
		for (let i = 0, ii = nodes.length; i < ii; i++) {
			node = nodes[i];
			fname = node.getAttribute('name');
			if (fname == field){
				result = node;
				break;
			}
		}
	}catch(e){
		//alert('an error occurred in getVOFieldValue while trying to get data for: ' + field);
		throw e;
	}
	nodes = node = fname = null;
	return result;
}

/**
* Returns the node for the field you are looking for.
* @param xml Any xml block that has a valueobject embedded in it.
* @param fiedl the value object field you are looking for
* @param voLookupKey: (optional) the vo attrib that will be used to lookup
* 					  the field. Default is 'name'.
*/
export function getVOFieldNode(xml, field, voLookupKey){

	if (!xml) return;

	if (!voLookupKey)
		voLookupKey = 'name';

	let result = null, nodes, node, fname;
	try{
		nodes = xml.selectNodes('valueobject/field');
		for (let i = 0, ii = nodes.length; i < ii; i++) {
			node = nodes[i];
			fname = node.getAttribute(voLookupKey);
			if (fname == field){
				result = node;
				break;
			}
		}
		nodes = node = fname = null;
		return result;
	}catch(e){
		result = null;
		//throw e;
	}
	// Evaluate xml as an object
	result = null;
	try {
		if (typeof xml[field] != 'undefined')
			result = xml[field];
	} catch(e){
		result = null;
		//throw e;
	}
	return result;
}

/**
* Sets the node value for a given node.
*/
export function setNodeValue(node, value, root){

	if (!node)
		return;

	if (node.firstChild)
		node.firstChild.nodeValue = value;
	else
		addTextToEmptyNode(node, value, root);

	/*
	if(_SARISSA_IS_IE){
		node.text = value;
	}else{
		node.childNodes[0].nodeValue = value;
	}
	*/
}

export function createPropertyNode(root, appendToNode, property, propertyValue, textVal, screenName){

	let fieldNode = root.createElement('field');
	appendToNode.appendChild(fieldNode);
	fieldNode.setAttribute(property, propertyValue);
	textVal = textVal?textVal:'';
	setNodeValue(fieldNode, textVal, root);
}

/**
 * Returns an ListItem node with ValueObject appended, from a tree xml node.
 *
 * @param xml - The xml from the tree ( Required )
 * @param root -  ( Optional )
 * @param type - ( Optional )
 * @param overideVOAttrib - ( Optional )
 * @param overideVOAttribVal - ( Optional )
 * @returns
 */
export function createListItemFromTreeNode(xml, root, type, overideVOAttrib, overideVOAttribVal){

	if (!root)
		root = xml.ownerDocument;

	let listElement = root.createElement('ListItem');
	let VOElement = null;

	// if xml is not null, copy over the icon and id elements from the tree obj.
	if (xml){
		let treeIcoObj = getSingleNode(xml, 'icon');
		let treeIdObj = getSingleNode(xml, 'id');

		let iconElement = treeIcoObj.cloneNode(true);
		let idElement = treeIdObj.cloneNode(true);

		listElement.appendChild(iconElement);
		listElement.appendChild(idElement);

		// copy over the value object from the tree xml
		let treeVO = getSingleNode(xml, 'valueobject');
		VOElement = treeVO.cloneNode(true);
	}else{
		VOElement = root.createElement('valueobject');
	}

	// update the valueobject's type
	if(!type)
		type = getVOFieldValue(xml, 'type');
	VOElement.setAttribute('type', type);

	//
	if (overideVOAttrib){
		VOElement.setAttribute(overideVOAttrib, overideVOAttribVal);
	}
	listElement.appendChild(VOElement);

	return listElement;
}

export function getOwnerDoc(node){
    try {
		let doc = node;
        if ( !(doc instanceof Document) && node.ownerDocument) // have to be careful because IE11 returns null for node.ownerDocument if node is actually a Document already.
            doc = node.ownerDocument;
        return doc;
    }catch(error){
        console.debug('getOwnerDoc failed: ' + error);
    }
}

/**
* Adds a text value to an empty node ...
* e.g. if you have <field name='SHORT_NAME'></field>
* and you want to insert 'jackson' as the value...so it looks like this:
*   <field name='SHORT_NAME'>jackson</field>
*/
export function addTextToEmptyNode(node, text, root){
	let ele = root.createTextNode(text);
	node.appendChild(ele);
}
/**
* Get a text representation of an XML Document
*/
export function getXMLText(doc){
	if (doc && typeof doc.xml == 'string')
		return doc.xml;
	let xmlString = new XMLSerializer().serializeToString(doc);
	return xmlString;
}

export function getText(node){
	let text = '';
	if(node){
		text = Sarissa.getText(node);
	}
	return text;
}

export function getDOMFromXMLString(XMLString){

	let oDomDoc = (new DOMParser()).parseFromString(XMLString, "text/xml");
	return oDomDoc;

}

export function removeAllXMLChildNodes(XMLNode) {
    if (XMLNode && XMLNode.hasChildNodes ) {
        while (XMLNode.hasChildNodes()) {
            XMLNode.removeChild(XMLNode.firstChild);
        }
    }
} // removeAllChildNodes()

export function removeAllXMLAttributes(XMLNode) {
	if (XMLNode && XMLNode.attributes ) {
		while(XMLNode.attributes.length > 0)
    		XMLNode.removeAttribute(XMLNode.attributes[0].name);
	}
} // removeAllChildNodes()


/**
* For a value object, retrieve the caption for the specified static value
*
*/
export function getSchemaStaticCaptionByValue( schemaType, fieldName, staticValue)
{
	let field = null;
	let fname = null;
	let staticField = null;

	let globalCache = getGlobalByName('globalSchemaCache');
	let xmlSchema = globalCache.get(schemaType);
	let fields = xmlSchema.selectNodes('//field');

	for (let i = 0, ii = fields.length; i < ii; ++i)
	{
		field  = fields[ i ];
		fname  = field.getAttribute( 'name' );
		if( fname == fieldName )
		{
			let staticFields = field.selectNodes('//static');
			for (let x = 0; x < staticFields.length; ++x)
			{
				staticField = staticFields[ x ];
				if(staticField.getAttribute( 'name' ) == staticValue)
					return staticField.getAttribute( 'caption' );
			}

			return null;
		}
	}
}

/**
 *
 * @param node
 * @param {String} attribute_name
 * @return {String}
 */
export function getAttributeSafely(node, attribute_name) {
	let value = '';
    try {
    	if (node != null && typeof attribute_name == 'string' && attribute_name != '')
    		value = node.getAttribute(attribute_name);
    } catch (e) {value = '';}
    return value;
}

export function getCDATAValue(node){
	let CDataSec = node.firstChild; //  NOTE: first child assumption could cause problems if more than one child here.
	if (CDataSec)
		return CDataSec.data;
	else
		return '';
}

export function updateContentNodeAsCDATA(node, text, colXML){

	let CDataSec = node.firstChild; //  NOTE: first child assumption could cause problems if more than one child here.
	if (!CDataSec){
		CDataSec = colXML.createCDATASection(text);
		node.appendChild(CDataSec);
	}else
		CDataSec.data = text;

}


