/* ? Copyright 2014 EcoSys Management LLC ? All Rights Reserved -- CONFIDENTIAL */
'use strict';

import {addQALocatorAttr, getGlobalByName, kt} from "../../common/ESGlobalsUtil";
import {getVOFieldValue} from "../../scripts/xmlhelper";
import {ESLOCAL} from "../../common/ESGlobals";
import {getXMLFromServerAsync} from "../../scripts/clientserver";
import {htmlToEntities} from "../../scripts/util";

import './dropdown.scss';

if (!window.__ESDropdownList);
    window.__ESDropdownList = [];

/**
 * Get an ESDropdown object by name
 * @param name
 * @return {ESDropdown}
 */
window.ESDropdownFind = function( name )
{
    for( let i = 0; i < window.__ESDropdownList.length; ++i ){
        if ( window.__ESDropdownList[ i ].name == name ){
            return window.__ESDropdownList[ i ];
        }
    }
    return null;
};
// adds a Dropdowns to the list of Dropdowns.
// if one already exists with the same name it will be replaced by the new one.
window.ESDropdownPush = function( Dropdown ) {

    for ( let i in window.__ESDropdownList ){
        if ( window.__ESDropdownList[ i ].name == Dropdown.name ){
            window.__ESDropdownList[ i ] = Dropdown;
            return;
        }
    }
    window.__ESDropdownList.push( Dropdown );
};
// Helper classes for backwards compat w/ old ESDropdown.
window.MenuItemObj = function(type){
    this.type = type?type:'ITEM';  // ITEM or SEPARATOR
    MenuItemObj.prototype.setAttribute = function(attr, val){
        this[attr] = val;
    };
};

export default class ESDropdown{

    constructor(objName, type, parentDhxMenu, siblingMenuId) {
        // to differentiate between other dropdowns.;
        this.name = objName;

        // register this in the global list
        ESDropdownPush(this);

        this.dhxMenu = parentDhxMenu ? parentDhxMenu : null; // the underlying dhtmlxmenu object (can chain these together by passing the parent).
        this.siblingMenuId = siblingMenuId; // required if chainign together several ESDropdowns.

        // will show in the controlContainer and can be clicked.
        this.labelKey = null;
        this.labelString = null;
        this.labelWhite = false;
        this.labelTooltip = null;
        // option to have the label get updated with the selected item or not
        // default is false (will update the label)
        this.noUpdateLabel = false;

        this.cornerMenu = false; // Set to true to change the icon to one that fits nicely in the upper left corner of a screen.
        this.cornerMenuImg = 'fa epc-menu-2 fa-thin';

        this.openOnMouseOver = false;

        this.linkHref = null;
        this.linkSpan = null;
        this.linkIcon = null;

        this.align = 'LEFT'; // or RIGHT -- the alignment of the dropdown relative to the control

        this.type = 'MISC'; // set to 'MENU' if you want to make this a standard dropdown menu with standard HREFs for the clickable items.
        if (type)
            this.type = type;

        this.menuItems = null; // used only for 'MENU' types -- make this 2D if multi top level (like spreadsheets)
        this.menuItemEvents = [];
        this.itemId = null;

        this.selectedValue = null;

        // When a dropdown has a lot of items, and grows past this val, then a scroll bar will appear.
        // and it won't get any taller. (val is in number of items).
        this.overflowHeight = null;
        this.overflowUseRightScroll = false;

        // *** Eventsf
        this.okClicked = null;
        this.onAfterInit = null;  // optional callback after data loads
        this.onShowMenu = null; // event handler fires just before a menu shows.

        // controls to be set by user
        this.controlContainer = null;
        this.controlContainerBaseClasses = ['dropdownControlContainer'];
        this.dropdownContent = null;
        this.parentContainer = document.body;
        this.useBodyForVisibleArea = false; // if you set this to true, large menus will use all possible space before trying to scroll.

        // will be passed in onclick event if set
        this.parentControlName = null;
        this.parentControl = null;


        // **** internal use only.

        this.DROPDOWN_NO_SELECTION_DISPLAY = kt('combo.option.noSelection');
        this.imageDir = getGlobalByName("globalImagePath");
        // boolean - true if dropdown is showing, false if not
        this.showing = false;

    }

    init (){

        let prevMenuId = null;
        if (!this.dhxMenu){

            this.dhxMenu = new dhtmlXMenuObject(this.controlContainer, "eco_dropdown");
            //this.dhxMenu.setIconsPath(getGlobalByName("globalImagePath") + "icons/");
            this.dhxMenu.setIconset("awesome");
            if (this.overflowHeight)
                this.dhxMenu.setOverflowHeight(this.overflowHeight);
            else
                this.dhxMenu.setOverflowHeight('auto');

            this.dhxMenu.conf.of_ah.eco_dropdown = 22; // needed for overflow height
            this.dhxMenu.conf.of_ih.eco_dropdown = 20; // needed for overflow height

            this.dhxMenu.setAlign(this.align.toLowerCase());
            this.dhxMenu.setOpenMode(this.openOnMouseOver?"web":"win");
            this.dhxMenu.attachEvent("onClick", (id) => {

                let dropName = this.dhxMenu.getUserData(id, 'parentDropdownName');
                let thisDrop = ESDropdownFind(dropName || id);
                if( thisDrop && typeof(thisDrop.menuItemEvents[id]) == 'function')
                    thisDrop.menuItemEvents[id].apply(thisDrop.parentControl?thisDrop.parentControl:thisDrop, [id]);
                else
                    return false;
                return true;
            });
            this.dhxMenu.attachEvent("onShow", (id) => {

                // first hide any others that are open
                for( let i = 0; i < __ESDropdownList.length; ++i ){
                    if (  __ESDropdownList[ i ].dhxMenu != this.dhxMenu ){ // not itself.
                        __ESDropdownList[ i ].closeMenu();
                    }
                }

                let dropName = this.dhxMenu.getUserData(id, 'parentDropdownName');
                let thisDrop = ESDropdownFind(dropName || id);
                if( thisDrop && typeof(thisDrop.onShowMenu) == 'function')
                    thisDrop.onShowMenu.apply(thisDrop.parentControl?thisDrop.parentControl:thisDrop, [id]);
                else
                    return false;
                return true;
            });
            if (getGlobalByName('globalSpreadsheetTesting')) {
                this.dhxMenu.onRenderSublevelPolygon = (div) => {
                    addQALocatorAttr(div, this.scarfShortName, this.name.split('-')[0]);
                }
            }

            //  if there is a selectedvalue set, then we should get the label from item.

            if ( this.selectedValue && this.selectedValue != ''){
                for (let i=0; i<this.menuItems.length; i++){
                    if (this.menuItems[i].itemId == this.selectedValue){
                        this.labelString = this.menuItems[i].labelString;
                    }
                }
            }
        }else{
            prevMenuId = this.siblingMenuId;
        }

        let img = null;

        if (this.type != 'MENU') {
            if (!this.labelWhite) {
                img = 'fa epc-down-dir fa-thin';
            }
            else
                img = 'fa epc-down-dir fa-thin fa-inverse';
        }

        if (this.cornerMenu){
            img = this.cornerMenuImg;
        }

        // create the main menu
        let tlbl = this.labelString;
        if (this.labelKey)
            tlbl = kt(this.labelKey);
        this.dhxMenu.addNewSibling(prevMenuId, this.name, tlbl, false, img);
        if (this.labelTooltip)
            this.dhxMenu.setTooltip(this.name, this.labelTooltip);

        this.loadChildItems();

        setTimeout(()=>{
            if (this.setVisibleArea)
                this.setVisibleArea(this.controlContainer);
        },100);

        if( this.onAfterInit)
            this.onAfterInit(this);
    }

    reloadChildItems(subMenuId){

        if (subMenuId && this.menuItems) {
            this.menuItems.forEach((item)=>{
                if (this.dhxMenu && item.parentMenuId == subMenuId) {
                    this.dhxMenu.forEachItem((mId) => {
                        if (item.itemId == mId)
                            this.dhxMenu.removeItem(mId);
                    });
                }
            });
        }

        if (this.dhxMenu)
            this.loadChildItems(subMenuId);
    }

    loadChildItems(onlySubMenuId){
        // load child items.
        if (this.menuItems){

            let item, icon, parentId;
            for (let k=0; k<this.menuItems.length; k++){
                item = this.menuItems[k];
                if (onlySubMenuId && item.parentMenuId != onlySubMenuId)
                    continue;
                if(item.type == 'ITEM'){
                    parentId = item.parentMenuId?item.parentMenuId:this.name;
                    icon = item.image?item.image:null;

                    let tlbl = item.labelString;
                    if (item.labelKey)
                        tlbl = kt(item.labelKey);
                    this.dhxMenu.addNewChild(parentId, k, item.itemId, tlbl, false, icon);
                    this.dhxMenu.setUserData(item.itemId, 'parentDropdownName', this.name);
                    if(item.hotkeyString) {
                        let tlbl = item.hotkeyString;
                        this.dhxMenu.setHotKey(item.itemId, tlbl);
                    }
                    // if there is a tooltip, render it
                    if(item.tip)
                        this.dhxMenu.setTooltip(item.itemId, item.tip);
                    // need to reorder click event handler functions, so they can be refed by id.
                    if (item.onclick)
                        this.menuItemEvents[item.itemId] = item.onclick;
                }else if(item.type == 'SEPARATOR'){
                    // dhxMenu will add the sep just after the itemId of the 1st param.
                    // so search up and find the first item before that has the right parent.
                    let beforeMenu;
                    for(let m=k-1; m>=0; m--){
                        if (this.menuItems[m].parentMenuId == item.parentMenuId) {
                            beforeMenu = this.menuItems[m];
                            break;
                        }
                    }
                    if (beforeMenu) {
                        try {
                            this.dhxMenu.addNewSeparator(beforeMenu.itemId, item.itemId);
                        }catch(error){
                            console.log('error adding sep. Before.itemId / itemId: ', beforeMenu.itemId, item.itemId );
                        }
                    }
                }
            }

        }

    }

    /**
     * The area used to calc if scrolls need to appear if too many menu items.
     * @param topCont
     */
    setVisibleArea(topCont){
        // need to update the auto overflow params since the container might have been rsized, etc.
        let pObj = this.parentContainer;
        if (!pObj)
            return;
        if (!topCont || this.useBodyForVisibleArea)
            topCont = document.body;
        this.dhxMenu.setVisibleArea(
            window.dhx4.absLeft(pObj),
            window.dhx4.absLeft(pObj)+pObj.offsetWidth,
            window.dhx4.absTop(topCont)+50,
            window.dhx4.absTop(pObj)+(pObj.offsetHeight)
        );

        if (this.overflowUseRightScroll){
            $('div[id$='+this.name+'] > div > table.dhtmlxMebu_SubLevelArea_Tbl').parent().css('overflow-y', 'auto');
        }

    }

    /**
     * Loads the menu items from a ListData Url
     * - url
     * - callback: the function that will be attached to all menu items
     * - selectedId: the id that will be selected by default.
     */
    async loadFromURL(url, callback, selectedId) {
        const [xml] = await getXMLFromServerAsync(url);
        this.loadFromXML(xml, callback, selectedId);
    }

    /**
     * Loads menu items from ListData xml
     * - xml is what you want loaded
     * - callback: the function that will be attached to all menu items
     * - selectedId: the id that will be selected by default.
     */
    loadFromXML (xml, callback, selectedId) {

        let cback = callback;

        let isTreeXML = false;
        let options = xml.selectNodes('ListData/ListItem');
        // might be this is a tree/item xml, let's try
        if (!options || options.length == 0){
            options =  xml.selectNodes('tree/item');
            if (options)
                isTreeXML = true;
        }
        let firstMenu;

        for(let i = 0; i < options.length; i++){
            let itemId = '';
            let displayText = '';
            let icon = '';
            if (!isTreeXML){
                itemId = Sarissa.getText(options[i].selectSingleNode('id'));
                displayText = Sarissa.escape(Sarissa.getText(options[i].selectSingleNode('display')));
                icon = Sarissa.getText(options[i].selectSingleNode('icon'));
            }else{
                itemId = getVOFieldValue(options[i],'iid');
                displayText = options[i].getAttribute('text');
                icon = options[i].getAttribute('im0');
            }

            let menuItem = new MenuItemObj();
            menuItem.id = this.name + '_menuItem_' + itemId;
            menuItem.itemId = itemId;
            menuItem.image = icon;
            menuItem.labelString = htmlToEntities(displayText);
            menuItem.optionNode = options[i];

            menuItem.onclick = (itemId) => {

                let menuItem =  this.getMenuItemById(itemId);
                if (! this.noUpdateLabel)
                    this.updateLabel(displayText);
                this.selectedValue = itemId;

                if (cback)
                    cback(menuItem.itemId, displayText, menuItem.optionNode );
            };

            if(!this.menuItems)
                this.menuItems = [];

            this.menuItems.push(menuItem);
            if (i==0)
                firstMenu = menuItem;
        }


        this.init();

        // if there's a selected Id passed in, we'll use it
        // otherwise let's default the 1st menu to being clicked
        if(selectedId)
            this.selectItemById(selectedId);
        else if (firstMenu)
            firstMenu.onclick(firstMenu.itemId);

    }

    /**
     * Helper method to add basic dropdownitems
     * Note: this is mostly just for a demo feature, to put in an extra static option at the top of
     * the dropdown list when it loads from XML.  It doesn't handle empty lists or when the pre-selected
     * item is one of these inserted items.  If you're going to use this method for real, better make
     * sure it works with all cases.
     */
    insertDropdownItem (text, value, icon, cback){

        // TODO ... if this is ever needed, use dhxMenu to insert values quickly.
    }

    setVisible (visible){
        if (visible)
            this.controlContainer.style.display = '';
        else
            this.controlContainer.style.display = 'none';
    }

    updateLabel (label){
        if (label)
            this.dhxMenu.setItemText(this.name, Sarissa.escape(label), '');
    }

    updateLabelKey (labelKey){
        let tlbl = kt(labelKey);
        this.dhxMenu.setItemText(this.name, Sarissa.escape(tlbl), '');
    }

    updateItemLabel (itemValue, label){
        let tlbl = label;
        this.dhxMenu.setItemText(itemValue, Sarissa.escape(tlbl), '');
    }

    updateItemLabelNoTrans (itemValue, label){
        if (this.dhxMenu)
            this.dhxMenu.setItemText(itemValue, Sarissa.escape(label), '');
    }

    removeItem (itemId){
        if (this.menuItems) {
            this.menuItems = this.menuItems.filter(
                (item) => {
                    return item.itemId != itemId;
                }
            );
        }
        try {
            this.dhxMenu.removeItem(itemId);
        }catch(e){
            console.debug('ESDropdown.removeItem error: ', e);
        }
    }

    setItemChecked (itemValue, checked){
        if (!this.dhxMenu)
            return;

        try {
            if (checked)
                this.dhxMenu.setItemImage(itemValue, "fa epc-ok");
            else
                this.dhxMenu.clearItemImage(itemValue);
        }catch(e){   }
    }

    /**
     * Select's and optionally 'clicks' the item for the passed in id
     */
    selectItemById (itemId, noclick){

        let thisId = itemId;
        if (!itemId && this.itemId)
            thisId = this.itemId

        let menuItem = this.getMenuItemById(thisId);
        if (!this.noUpdateLabel)
            this.updateLabel(menuItem.labelString);
        if (!noclick){
            menuItem.onclick();
        }
        this.selectedValue = thisId;

    }

    setMenuItemDisabled (itemId, disabled){

        if (!this.dhxMenu) return;

        try {
            if (disabled == true)
                this.dhxMenu.setItemDisabled(itemId);
            else
                this.dhxMenu.setItemEnabled(itemId);
        }catch(e){   }
    }

    setMenuItemVisible (itemId, isVisible){

        if (!this.dhxMenu) return;

        try {
            if (isVisible == true)
                this.dhxMenu.showItem(itemId);
            else
                this.dhxMenu.hideItem(itemId);
        }catch(e){   }
    }

    setSeparatorVisible (itemId, isVisible){

        if (!this.dhxMenu) return;

        try{
            if (isVisible == true)
                this.dhxMenu.showItem(itemId);
            else
                this.dhxMenu.hideItem(itemId);
        }catch(e){   }
    }

    closeMenu(){
        this.dhxMenu.hide();
    }

    /**
     * Allows labelString instead of labelKey to passed in.
     * @param labelString
     * @param itemId
     * @param onclick
     * @param hotkey
     * @param subMenuParentId
     * @param subMenuParentControlName
     * @param img
     * @param tip
     * @returns {*|Window.MenuItemObj}
     */
    addMenuItemAlt(labelString, itemId, onclick, hotkey, subMenuParentId, subMenuParentControlName, img, tip){
        let item = this.addItem('', itemId, onclick, hotkey, subMenuParentId, subMenuParentControlName, img, tip)
        item.labelString = labelString;
        return item;
    }

    /**
     * Helper method to add basic item
     */
    addItem (labelkey, itemId, onclick, hotkey, subMenuParentId, subMenuParentControlName, img, tip){

        // no dups
        if (this.getMenuItemById(itemId))
            return this.getMenuItemById(itemId);

        let menuItem = new MenuItemObj();
        menuItem.id = this.name+'_menuItem_'+itemId;
        menuItem.itemId = itemId;
        menuItem.labelString = kt(labelkey);
        menuItem.hotkeyString = hotkey;
        menuItem.parentMenuId = subMenuParentId;
        menuItem.image = img;
        menuItem.tip = tip;
        menuItem.onclick = (itemId) => {

            let menuItem = this.getMenuItemById(itemId);
            if (!this.noUpdateLabel)
                this.updateLabel(menuItem.labelString);
            this.selectedValue = itemId;

            if (onclick) {
                let pName = this.parentControlName;
                if(subMenuParentControlName)
                    pName = subMenuParentControlName;
                onclick(itemId, pName, menuItem.labelString, this);
            }
        };

        if(!this.menuItems)
            this.menuItems = [];

        this.menuItems.push(menuItem);

        return menuItem;
    }

    getMenuItemById (itemId){
        if (!this.menuItems) return;

        for (let i=0; i<this.menuItems.length; i++){
            if (this.menuItems[i].itemId == itemId){
                return this.menuItems[i];
            }
        }
    }

    addSeparator (size, id, parentMenuId){

        // no dups
        if (this.getMenuItemById(id))
            return this.getMenuItemById(id);

        let sep = new MenuItemObj();
        sep.type = 'SEPARATOR';
        sep.itemId = id;
        sep.parentMenuId = parentMenuId;
        if(!this.menuItems)
            this.menuItems = [];
        this.menuItems.push(sep);
    }

    setDisabled (disable){

        // top level menu is id of this.name
        this.setMenuItemDisabled(this.name, disable);
    }

    destroy (restoreContClasses) {
        for(let i = __ESDropdownList.length-1; i >= 0; i-- ){
            if ( __ESDropdownList[ i ].name == this.name ){
                __ESDropdownList.splice(i, 1); // Remove from registry
            }
        }
        if (this.controlContainer) {
            this.controlContainer.innerHTML = '';
            if (restoreContClasses && this.controlContainerBaseClasses && this.controlContainerBaseClasses.length>0)
                this.controlContainer.classList.add(...this.controlContainerBaseClasses);
            this.controlContainer = null;
        }
        this.dropdownContent = null;
        this.menuItems = [];
        ESLOCAL.destroyer(this, true);
        this.dhxMenu = null;
        return null;
    }

}

// EVENTS

