'use strict';

import {getText} from "../../scripts/xmlhelper";
import {kt} from "../../common/ESGlobalsUtil";
import {getStringBuilder} from "../../scripts/util";

let SCS_popup = null;

// This array is 1-d and simply contains the Column XML Nodes
// The index of each node in the array is the same as the 'value'
// parameter in the select control.
let SCS_colArray = [];
let SCS_colAvailArray = [];  // the avail items on the left list

function SingleColumnSelector_assignAndClose(){

	if ( !SCS_popup.useXMLModel){
		SCS_popup.itemAssigned( SingleColumnSelector_getColIdArray() );
	}
	SCS_popup.hide();

}

function SingleColumnSelector_loadSelectList(){

	// loop through colNodes and get display element
	let selectList = document.getElementById('sc_col_list');
	removeAllOptions(selectList);

	if ( !SCS_popup.useXMLModel){
		for (let i=0; i< SCS_popup.colIdArray.length; i++){

			// Store the id in the 'value' of the select control.
			let id = SCS_popup.colIdArray[i];
			let display = SCS_popup.colDisplayArray[i];

			// add a row to the select control
			appendOptionLast(selectList, display, id);

		}
	}else{ // use XMLModel
		for (let i=0; i< SCS_popup.colXMLArray.length; i++){
			let fieldNode = SCS_popup.colXMLArray[i];
			let text = getText(fieldNode);
			if ( fieldNode.getAttribute('visible') == 'Y' ) { // don't add hidden fields to the list.
				// add a row to the select control
				appendOptionLast(selectList, text, i);
				SCS_colArray[i] = SCS_popup.colXMLArray[i];
			}
		}
	}

}

function SingleColumnSelector_loadAvailSelectList(){

	// loop through colNodes and get display element
	let avail_list = document.getElementById('sc_avail_col_list');
	let list = document.getElementById('sc_col_list');

	removeAllOptions(avail_list);

	if ( !SCS_popup.useXMLModel){
		for (let i=0; i< SCS_popup.colAvailIdArray.length; i++){

			// Store the id in the 'value' of the select control.
			let id = SCS_popup.colAvailIdArray[i];
			let display = SCS_popup.colAvailDisplayArray[i];

			// check if this item is already in the selected list
			if (! optionExists(list, text)){
				// add a row to the select control
				appendOptionLast(avail_list, display, id);
			}
		}
	}else{ // use XMLModel
		for (let i=0; i< SCS_popup.colAvailXMLArray.length; i++){

			let fieldNode = SCS_popup.colAvailXMLArray[i];
			let text = getText(fieldNode);

			if ( fieldNode.getAttribute('visible') == 'Y' ) { // don't add hidden fields to the list.

				// check if this item is already in the selected list
				if (! optionExists(list, text)){
					// add a row to the select control
					appendOptionLast(avail_list, text, i);
					SCS_colAvailArray[i] = SCS_popup.colAvailXMLArray[i];
				}
			}
		}
	}

	sortOptions(avail_list);
	//alert('just sorted');
}

/**
 * Re-orders the ColXML nodes using our local colArray.
 * Basically it just adds them into a new array and returns them.
 */
function SingleColumnSelector_getSelectedColXML(){

	// use the colArray and list control to re-order the nodes in colXMl.
	// In order to remove deleted nodes, we'll need to re-create the parent,
	// and then add the good guys back.
	let newCols = [];
	let list = document.getElementById('sc_avail_col_list');

	//parent.debug('list.options.length: ' + list.options.length);

	// Re-add the child to the newColsNode
	for (let i=0;i<list.length;i++){
		if (list.options[i].selected == true){
			let ind = list.options[i].value;
			newCols.push(SCS_colAvailArray[ind]);
		}
	}

	//parent.debug('getting jiggy: ' + newCols);
	return newCols;
}

/**
 * Returns an array of ids in the SCS_popup control which reflect the new values
 * the user had selected, etc. This will also replace the popup.colIdArray.
 */
function SingleColumnSelector_getColIdArray(){

	//
	let ids = [];
	let list = document.getElementById('sc_col_list');

	// Loop through the list and get the ids out of the 'value' attrib.
	for (let i=0;i<list.options.length;i++){
		ids[i] = list.options[i].value;
	}

	// Replace the old ids array w/ da new
	SCS_popup.colIdArray = ids;

	return ids;
}

export function SingleColumnSelector_getDOMComponents(name){

	let table = document.createElement('TABLE');
	table.style.width = '95%';
	table.style.height = '80%';
	table.style.margin = '10px';

	let row = table.insertRow();

	let cell = row.insertCell();
	cell.style.width = '40%';
	cell.valign = 'top';
	cell.style.border = 'none';
	cell.style.padding = '3px 3px 3px 10px';
	cell.style.verticalAlign = 'top';

	let selectContainer = document.createElement('DIV');
	let lblDiv = document.createElement('DIV');
	lblDiv.innerHTML = kt('popup.single.available.text');// 'Available Items';
	selectContainer.appendChild(lblDiv);

	let select = document.createElement('SELECT');
	select.setAttribute('id', 'sc_avail_col_list');
	select.size="16";
	select.multiple = "multiple";
	select.style.width = '235px';
	select.style.marginTop = '3px';
	selectContainer.appendChild(select);

	cell.appendChild(selectContainer);

	// this is just an invisible select to make all the code work.
	select = document.createElement('SELECT');
	select.setAttribute('id', 'sc_col_list');
	select.style.display = 'none';
	cell.appendChild(select);

	return table;

}

function SingleColumnSelector_getHTML(){

	let html = getStringBuilder();
	html.append('<table width="98%" height="80%" style="padding: 5px 5px 5px 5px; margin: 5px 5px 5px 5px;">');
	html.append('	<tr>');
	html.append('		<td width="40%" valign="top" style="border: none; padding: 3px 3px 3px 10px;">');
	html.append(			kt("popup.single.available.text")+'<br>');  //('			Available Items<br>');
	html.append('			<select id="sc_avail_col_list" size="16" MULTIPLE style="width: 235px; margin-top: 3px;" >');
	html.append('			</select>');
	html.append('			<select id="sc_col_list" size="16" MULTIPLE style="display: none; width: 235px; margin-top: 3px;" >');
	html.append('			</select>');
	html.append('		</td>	');
	html.append('	</tr>');
	html.append('</table>');

	return html.toString();
}


export function SingleColumnSelector_init(thePopup){

	SCS_popup = thePopup;

	SingleColumnSelector_loadSelectList();
	SingleColumnSelector_loadAvailSelectList();

}
